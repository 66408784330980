import Vue from 'vue'

/**
 * Используем только необходимые иконки
 */
// import { library } from '@fortawesome/fontawesome-svg-core'
// import {
//   faCheck, faCheckCircle, faInfoCircle, faExclamationTriangle, faExclamationCircle,
//   faArrowUp, faAngleRight, faAngleLeft, faAngleDown, faPlus, faMinus,
//   faEye, faEyeSlash, faCaretDown, faCaretUp, faUpload
// } from '@fortawesome/free-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/**
 * самые часто используемые
 * компоненты Buefy
 * @see https://github.com/buefy/buefy/issues/1955
 */
// import { ConfigProgrammatic } from 'buefy'
import config from 'buefy/src/utils/config'
import {
  Button,
  Dialog,
  Field,
  Icon,
  Input,
  // Numberinput,
  // Radio,
  // Select,
  // Slider,
  // Tabs,
  Modal,
  // Toast,
  // Table,
  Notification,
  // Upload,
  Message
  // Rate
} from 'buefy/src/components'
//
// library.add(faCheck, faCheckCircle, faInfoCircle, faExclamationTriangle, faExclamationCircle,
//   faArrowUp, faAngleRight, faAngleLeft, faAngleDown, faPlus, faMinus,
//   faEye, faEyeSlash, faCaretDown, faCaretUp, faUpload)
// Vue.component('vue-fontawesome', FontAwesomeIcon)

/**
 * @see https://buefy.org/documentation/constructor-options/
 */
config.defaultIconPack = 'fas'
// config.defaultIconComponent = 'vue-fontawesome'

/**
 * Подключать только используемые на сайте
 * компоненты. Стили подключаются отдельно
 * в src/sass/_buefy.scss
 */
Vue.use(Dialog)
// Vue.use(Rate)
// Vue.use(Numberinput)
Vue.use(Icon)
// Vue.use(Tabs)
// Vue.use(Select)
Vue.use(Input)
Vue.use(Field)
// Vue.use(Slider)
// Vue.use(Radio)
// Vue.use(Toast)
// Vue.use(Table)
Vue.use(Modal)
Vue.use(Notification)
Vue.use(Button)
Vue.use(Message)
// Vue.use(Upload)
