import Vue from 'vue'
import { extend, localize, configure, ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, length, max, min, regex } from 'vee-validate/dist/rules'
import ru from 'vee-validate/dist/locale/ru.json'

// extend('required', {
//   ...required
// })

localize('ru', ru)
configure({
  classes: {
    valid: 'is-success',
    invalid: 'is-danger'
  }
})

extend('required', required)
extend('email', email)
extend('length', length)
extend('max', max)
extend('min', min)
extend('regex', regex)

extend('total', (value) => {
  if (value > 3000) {
    return true
  }

  return 'Минимальная сумма заказа 3000 рублей'
})

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
