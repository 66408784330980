<template>
  <div class="feedback-form-local price-form" :class="{'form-in-modal': trigger === true}">
    <ValidationObserver v-slot="{ invalid, valid, handleSubmit }" ref="observer">
      <div ref="messages" class="form-messages">
        <b-notification v-model="status.error" type="is-danger" @close="onCloseNotification">
          <p>Ошибка! Не удалось отправить сообщение.</p>
          <p>Попробуйте снова, если ошибка повторяется, обновите страницу.</p>
          <template v-if="showExtendedError">
            <p>Описание ошибки:</p>
            <span v-for="(msg, index) in localErrors" :key="index">
              <strong>{{ index }}: {{ msg }}</strong>
            </span>
          </template>
        </b-notification>
        <b-notification v-model="status.success" type="is-success" @close="onCloseNotification">
          <p class="title is-5" style="margin-bottom: .5rem">
            Спасибо! Ваша заявка отправлена.
          </p>
          <p>
            Постараемся ответить как можно быстрее.
          </p>
        </b-notification>
      </div>
      <div v-if="isNotSuccess" class="form-wrapper">
        <div v-if="product.name === '-'">
          <div class="how-know-info">
            <p class="is-size-5"><strong>Написать менеджеру магазина</strong></p>
          </div>
          <br>
        </div>
        <div v-else>
          <div class="how-know-info">
            <p>Узнать цену Вы можете по телефонам: <br>
            <strong><a href="tel:+74872558070">+7 (4872) 55-80-70</a>, <a href="tel:+74872211330">+7 (4872) 21-13-30</a></strong> или <strong>заполните форму заявки</strong></p>
          </div>
          <div class="product product-card">
            <div class="product-image">
              <figure class="image-container">
                <img :src="product.image" :alt="product.name" >
              </figure>
            </div>
            <div class="product-card-content">
              <!--p>#{{product.id}}</p-->
              <p>{{ product.name }}</p>
            </div>
          </div>
        </div>
        
        

        <form id="price-form" ref="form" class="form px-4" @submit.prevent="handleSubmit(submitBitrix)">
          <input type="hidden" name="WEB_FORM_ID" :value="webFormId" />
          <input type="hidden" name="form_text_5" :value="product.name">
          <input type="hidden" name="form_text_6" :value="product.url">
          <input type="hidden" name="form_text_19" :value="product.form">

          <!--
              WEB_FORM_ID=2 (Узнать цену)

              form_text_5 - название товара *
              form_text_6 - ссылка на товар
              form_text_7 - ваше имя *
              form_text_8 - контактный телефон *
              form_email_9 - email *
              form_textarea_10 - комментарий
              form_checkbox_12 - согласие
              form_text_19 - Название формы
          -->

          <div class="columns is-multiline">
            <div class="column is-12">
              <ValidationProvider v-slot="v" name="Имя" rules="required" slim>
                <b-field
                    :type="{'is-success': v.valid, 'is-danger': (v.invalid && v.dirty)}"
                    :message="v.invalid && v.errors[0] || ''"
                    label="Имя, фамилия *"
                    expanded
                >
                  <b-input
                      v-model="fd.name"
                      name="form_text_7"
                      placeholder="Имя Фамилия"
                      :readonly="busy"
                  />
                </b-field>
              </ValidationProvider>
            </div>

            <div class="column is-6">
              <ValidationProvider v-slot="v" name="Телефон" :rules="{ required: true, regex: /^\d{3}\s\d{3}-\d{2}-\d{2}/ }" slim>
                <b-field
                    :type="{'is-success': v.valid, 'is-danger': (v.invalid && v.dirty)}"
                    :message="v.invalid && v.errors[0] || ''"
                    label="Телефон *"
                    expanded
                >
                  <b-field
                      :type="{'is-success': v.valid, 'is-danger': (v.invalid && v.dirty)}"
                  >
                    <p class="control">
                      <b-button class="is-primary" :type="{'is-success': v.valid, 'is-danger': (v.invalid && v.dirty)}">
                        +7
                      </b-button>
                    </p>
                    <b-input
                        v-model="fd.phone"
                        v-mask="'### ###-##-##'"
                        :readonly="busy"
                        native-type="tel"
                        name="form_text_8"
                        placeholder="000 000-00-00"
                        expanded
                    />
                  </b-field>
                </b-field>
              </ValidationProvider>
            </div>
            <div class="column is-6">
              <ValidationProvider name="Email" rules="required|email" v-slot="v" slim>
                <b-field
                    label="Email*"
                    :type="{'is-success': v.valid, 'is-danger': (v.invalid && v.dirty)}"
                    :message="v.invalid && v.errors[0] || ''"
                    expanded
                >
                  <b-input name="form_email_9" v-model="fd.email" placeholder="ваш@email.ru" />
                </b-field>
              </ValidationProvider>
            </div>
            <div class="column is-12">
              <ValidationProvider name="Комментарий">
                <b-field label="Комментарий">
                  <b-input type="textarea" name="form_textarea_10" v-model="fd.message" />
                </b-field>
              </ValidationProvider>
            </div>
          </div>

          <hr>

          <b-field class="form-buttons" expanded>
            <b-field>
              <b-button @click="$emit('close')">
                Отменить
              </b-button>
            </b-field>
            <b-field>
              <b-button
                  :disabled="invalid"
                  :loading="busy"
                  :rounded="false"
                  native-type="submit"
                  :class="{'is-primary': valid}"
              >
                Отправить
              </b-button>
            </b-field>
          </b-field>

          <b-field>
            <p class="helphas-text-centered pb-4" style="justify-content: center;">
              Нажимая кнопку «Отправить», даю свое согласие
              на
              <a href="/agreement/" target="_blank">обработку моих персональных данных</a>
            </p>
          </b-field>
        </form>
      </div>
    </ValidationObserver>
  </div>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'

/**
 * Форма обратной связи для работы через
 * server-middleware/mailer/*.
 *
 * Форма отправляет данные на локальный адрес,
 * на котором работает express/nodemailer.
 *
 * Это хорошо подходит для сайтов без подключения к
 * platform-api. Но работающие в SSR-режиме.
 */
export default {
  components: {
    ValidationObserver,
    ValidationProvider
  },
  props: {
    webFormId: {
      required: true,
      type: Number,
      default: 2
    },

    /**
     * Если форма открывается в
     * модальном окне, через trigger
     * необходимо указать это в параметрах
     * модального окна
     *
     * При работе с триггером меняется
     * логика отображения статусов и логика
     * сброса состояния формы
     */
    trigger: {
      type: Boolean,
      required: false,
      default: false
    },
    product: {
      type: Object,
      required: true,
      default: function () {
        return { }
      }
    }
  },
  data () {
    return {
      showExtendedError: false,
      busy: false,
      /**
       * @todo Динамическое определением имен
       * для элементов формы
       */
      formNames: {
        message: '',
        email: '',
        name: '',
        phone: '',
        form: ''
      },
      fd: {
        message: '',
        email: '',
        name: '',
        phone: '',
        form: ''
      },
      status: {
        pristine: true,
        success: false,
        error: false,
        dirty: false
      },
      localErrors: []
    }
  },
  computed: {
    hasErrors () {
      return this.localErrors.length > 0
    },
    isSuccess () {
      return this.status.success === true
    },
    isPristine () {
      return this.status.pristine === true
    },
    isNotSuccess () {
      return this.status.success !== true
    }
  },
  methods: {
    beforeSubmit () {
      this.status.dirty = true
      this.status.success = false
      this.status.error = false
      this.status.pristine = false
      this.localErrors.splice(0, this.localErrors.length + 1)
    },
    /**
     * Метод для работы в Битрикс окружении
     */
    submitBitrix () {
      if (!window.BX) {
        console.error('Require BX')
        this.$buefy.dialog.alert(`
          Ошибка!
          Отправить сообщение возможно только в контексте сайта (Битрикс).
          Отсутствует необходимая библиотека [ERR-0001].
         `)
        return
      }

      this.beforeSubmit()
      const form = new FormData(this.$refs.form)

      try {
        window.BX.ajax.runAction('platform:main.api.form.submit', {
          data: form
        })
            .then(() => {
              this.status.success = true
              this.resetForm()
              this.$emit('submit:success')
              this.$root.$emit('submit:success')
            })
            .catch(({ errors }) => {
              this.localErrors.push(errors)
              this.status.error = true
              this.$emit('submit:error')
              this.$root.$emit('submit:error')
            })
      } catch (e) {
        this.$buefy.dialog.alert('Ошибка! Не удается отправить сообщение. [ERR-0002]')
      }
    },
    resetState () {
      this.resetStatus()
      this.resetForm()
    },
    resetForm () {
      for (const field in this.fd) {
        if (Object.prototype.hasOwnProperty.call(this.fd, field)) {
          this.fd[field] = null
        }

        this.$nextTick(() => {
          this.$refs.observer.reset()
        })
      }
    },
    resetStatus () {
      this.status.success = false
      this.status.error = false
      this.status.dirty = false
      this.status.pristine = true
    },
    onCloseNotification () {
      if (this.isSuccess) {
        if (this.trigger) {
          this.$emit('close', this.isSuccess)
          this.$parent.$emit('submit:success')
          this.$destroy()
        } else {
          this.resetStatus()
        }
      } else {
        this.status.pristine = false
        this.status.dirty = true
      }
    }
  }
}
</script>
<style lang="scss">
.get-price-action {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  &.normal-width {
    max-width: max-content;
  }
  .know {
    cursor: pointer;
    padding: 0.5rem 0.8rem;
    text-align: center;
    width: 100%;
    color: #fff;
    &:hover {
      background: #7691a4;
    }
  }
}

.price-form {
  background: #fff;
  padding: 1.2rem;
  
  .modal-card-title {
  }

  .how-know-info {
    font-weight: 400;
    text-align: center;
    border-bottom: 1px solid #526a7c;
    padding-bottom: 1.2rem;
  }
  
  .form-buttons {
    .field-body {
      .field.has-addons {
        justify-content: space-between;
      }
    }
  }

  .product-card {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    justify-items: baseline;
    margin: 1.4rem 0;
    background-color: #f5f5f5;
    border-radius: 4px;
    padding: 1rem;

    .product-image {
      margin-right: 1rem;
      width: calc(50% - 1rem);
    }
    .image-container {
      display: block;
      padding-top: 75%;
      position: relative;   
      img {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        object-fit: scale-down;
        height: 100%;
        width: 100%;
        background: #fff;
      }
    }

    .product-card-content {
      font-size: 1.2rem;
      width: 50%;
      @media (max-width: 540px) {
        font-size: 1rem;
      }
    }
  }
}

</style>
