<template>
  <a @click="getPriceModal" class="know">
    {{productForm}}
  </a>
</template>

<script>
import Vue from 'vue'
import VueTheMask from 'vue-the-mask'
import PriceForm from '@/components/PriceForm'

require('./custom/buefy')
require('./vee-validate')

Vue.use(VueTheMask)

export default Vue.extend({
  //name: 'getPriceAction',
  data () {
    return {
      form: null
    }
  },
  props: {
    productUrl: {
      type: String,
      required: true,
      default: ''
    },
    productCatalog: {
      type: String,
      required: true,
      default: ''
    },
    productName: {
      type: String,
      required: true,
      default: ''
    },
    productId: {
      type: String,
      required: true,
      default: ''
    },
    productImage: {
      type: String,
      required: true,
      default: ''
    },
    webFormId: {
      type: Number,
      required: true,
      default: 2
    },
    productForm: {
      type: String,
      required: true,
      default: 'Узнать цену!'
    }
  },
  methods: {
    getPriceModal () {
      this.$buefy.modal.open({
        component: PriceForm,
        canCancel: ['x'],
        parent: this,
        props: {
          trigger: true,
          webFormId: this.webFormId,
          product: {
            url: this.productUrl,
            catalog: this.productCatalog,
            image: this.productImage,
            name: this.productName,
            id: this.productId,
            form: this.productForm
          }
        }
      }).$on('close', function () {
        console.log('modal:close')
      }).$on('submit:success', () => {
        console.log('modal:success')
      })
    }
  }
})
</script>
